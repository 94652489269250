import React, {
  FC,
  ReactElement,
  useEffect,
  useContext,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "@cloudscape-design/components";
import {
  RAW_COLUMNS,
  PREFERENCES_VISIBLE_COLUMNS,
  PREFERENCES_VISIBLE_CONTENT,
  FILTERING_PROPERTIES,
} from "./config";

import { AuthContext } from "../../context/AuthContext";
import CloudscapeTable from "../../components/table/CloudscapeTable";
import {
  API_CALL_NAME,
  ENTITY_TYPE,
  RESOURCE_CAMPAIGN,
  TABLE_SELECTION_TYPE_MULTI,
} from "../../data/constants/common";
import { getSavedPreferencesFromLocalStorage } from "../../components/table/Preferences";
import { updateTableColumnsWithCustomAttributes } from "../../data/config/commonDashboardConfig";

const CampaignDashboard: FC<object> = (): ReactElement | null => {
  const navigate = useNavigate();
  const { customCampaignAttributes: customAttributes } =
    useContext(AuthContext);
  const [rawColumns, setRawColumns] = useState(RAW_COLUMNS);
  const [preferenceContent, setPreferenceContent] = useState(
    PREFERENCES_VISIBLE_CONTENT
  );
  const [filteringProperties, setFilteringProperties] =
    useState(FILTERING_PROPERTIES);

  useEffect(() => {
    const rawColumnsUpdate: any[] = Object.assign([], RAW_COLUMNS);
    const preferenceContentUpdate = Object.assign(
      [],
      PREFERENCES_VISIBLE_CONTENT
    );
    const filteringPropertiesUpdate = Object.assign([], FILTERING_PROPERTIES);

    updateTableColumnsWithCustomAttributes(
      customAttributes,
      rawColumnsUpdate,
      preferenceContentUpdate,
      filteringPropertiesUpdate,
      setRawColumns,
      setPreferenceContent,
      setFilteringProperties,
    );

  }, [customAttributes]);

  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    navigate("/campaigns/create");
  };

  const handleOnEdit = (item: any): void => {
    navigate(`/campaigns/${item.businessunitSlug}/${item.tacsid}/edit`);
  };

  return (
    <div data-testid="campaign-dashboard">
      <CloudscapeTable
        columnDefinitions={rawColumns}
        resizableColumns={false}
        resourceName={RESOURCE_CAMPAIGN}
        apiNameCall={API_CALL_NAME.CAMPAIGNS}
        selectionType={TABLE_SELECTION_TYPE_MULTI}
        visibleContentDefault={PREFERENCES_VISIBLE_COLUMNS}
        visibleContentOptions={preferenceContent}
        filteringProperties={filteringProperties}
        handleOnCreate={handleOnCreate}
        actionHandlers={{
          handleOnEdit: handleOnEdit,
          hasArchiveButton: true,
        }}
        title={RESOURCE_CAMPAIGN}
        savedPreferences={getSavedPreferencesFromLocalStorage(
          RESOURCE_CAMPAIGN,
          PREFERENCES_VISIBLE_COLUMNS
        )}
        entityType={ENTITY_TYPE.CAMPAIGNS}
      />
    </div>
  );
};

export default CampaignDashboard;
