import React, { useContext, useEffect, useState } from "react";
import TaCSTableCloudscape from "../../../components/table/TaCSTableCloudscape";
import { AuthContext } from "../../../context/AuthContext";
import { ButtonProps, CollectionPreferencesProps } from "@cloudscape-design/components";
import {
  BUSINESS_GROUPS_TABLE_COLUMNS,
  PREFERRED_VISIBLE_COLUMNS_BG,
  VISIBLE_CONTENT_OPTION_PREFERENCE_BG,
} from "../config";
import { useFetchBusinessData } from "../../../client/client";
import { useNavigate } from "react-router-dom";
import { BUSINESS_ROUTE_PATHS, ITEM_UNIQUE_ID, REQUEST_METHOD } from "../../../data/constants/common";
import { getTablePreferencesFromLocalStorage } from "../../../data/config/commonDashboardConfig";
import { BUSINESS_PROPERTY } from "../Interface";
import { IBusinessGroup } from "../../../data/interfaces/IUser";

type Props = {
  getIdToken: () => string;
  selectedBG: IBusinessGroup | null;
}

export const BusinessGroups: React.FC<Props> = ({ getIdToken, selectedBG }) => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const [tablePreferences, setTablePreferences] = useState<
    CollectionPreferencesProps.Preferences>(() => {
      return getTablePreferencesFromLocalStorage(
        "businessGroups",
        PREFERRED_VISIBLE_COLUMNS_BG
      );
    });
  const navigate = useNavigate();
  
  const fetchParams = {
    urlPath: `/businessgroup/?currPage=${currentPage}&pageSize=${tablePreferences.pageSize}`,
    method: REQUEST_METHOD.GET,
    getTokenFn: getIdToken,
  };

  const { isLoading, data, serverError, refresh }
    = useFetchBusinessData(fetchParams);

  useEffect(() => {
    setTableData(data);
    setLoadingTableData(false);
  }, [isLoading, currentPage, tablePreferences]);

  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    navigate(`${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS}${BUSINESS_ROUTE_PATHS.CREATE}`);
  };


  const handleBGTableActions = (): void => {

    navigate(`${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_GROUPS}${BUSINESS_ROUTE_PATHS.EDIT}`, {
      state: {
        ...selectedItems[0],
      },
    });
  };

  return (
    <TaCSTableCloudscape
      tableHeading={`${BUSINESS_PROPERTY.BUSINESS_GROUP}s`}
      filteringPlaceholder={`Search for ${BUSINESS_PROPERTY.BUSINESS_GROUP}s`}
      businessProperty="businessGroups"
      primaryActionLabel={`Create ${BUSINESS_PROPERTY.BUSINESS_GROUP}`}
      isLoading={loadingTableData}
      data={tableData}
      serverError={serverError}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      tablePreferences={tablePreferences}
      setTablePreferences={setTablePreferences}
      columnDefinitions={BUSINESS_GROUPS_TABLE_COLUMNS}
      items={tableData?.businessGroups}
      visibleContentPreferenceOptions={VISIBLE_CONTENT_OPTION_PREFERENCE_BG}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      handleTablePrimaryAction={handleOnCreate}
      handleTableActions={handleBGTableActions}
      refetch={refresh}
      itemId={ITEM_UNIQUE_ID.BUSINESS_GROUP}
      idToken={getIdToken}
      customAttributes={null}
      selectedBG={selectedBG}
    />
  );
};

export const BusinessGroupsDashboard = () => {
  const { getIdToken, selectedBG, } = useContext(AuthContext);

  return (
    <BusinessGroups getIdToken={getIdToken} selectedBG={selectedBG} />
  );
};
