import { PropertyFilterProps } from "@cloudscape-design/components";
import { getEpochTime } from "../data/helpers/dates";
import { dateConfig, fixedSpendFields, freeTextConfig, numericFieldConfig, variableSpendFields } from "./opensearchConfig";
import { ENTITY_TYPE } from "../data/constants/common";

export function mapOperator(operator: string): string {
  switch (operator) {
  case ">=":
    return "gte";
  case "<=":
    return "lte";
  default:
    throw new Error("Invalid operator");
  }
}

function stringifyDateFilters(tokens: ReadonlyArray<PropertyFilterProps.Token>, entityType: string) {
  const filters = tokens.flatMap(token => {
    if (token.operator === "=") {
      return [
        {
          ...token,
          operator: ">="
        },
        {
          ...token,
          operator: "<="
        }
      ];
    } else {
      return token;
    }
  }).reduce((acc, token) => {
    if (dateConfig[entityType].includes(token.propertyKey as string)) {
      try {
        const filter = {
          range: {
            [token.propertyKey as string]: {
              [mapOperator(token.operator)]: getEpochTime(token.value)
            }
          }
        };
        acc.push(filter);
      } catch (error: any) {
        console.error(`Error processing date filter for ${token.propertyKey}: ${error.message}`);
      }
    }
    return acc;
  }, [] as any[]);
  return filters;
}


function stringifySearchQuery(query: PropertyFilterProps.Query, entityType: string, customAttributes?: []): string {
  const freeTextTokens = query.tokens.filter(token => !token.propertyKey)
    .map(token => formatFreeTextToken(token));

  // Filter out tokens that should not be processed.
  const relevantTokens = query.tokens.filter(token =>
    token.propertyKey && !dateConfig[entityType].includes(token.propertyKey)
  );

  if (!relevantTokens.length && freeTextTokens.length === 0) {
    return JSON.stringify({ match_all: {} });
  }

  const stringTokens = relevantTokens.map(token => formatToken(token));

  return JSON.stringify({
    query_string: {
      query: [...stringTokens, ...freeTextTokens].join(` ${query.operation.toUpperCase()} `),
      analyze_wildcard: true,
      fuzziness: "AUTO",
      fields: [...(customAttributes?.map((att: any) => `customattributes.${att?.slug}_label`) || []), ...freeTextConfig[entityType]],
    }
  });
}

function formatToken(token: PropertyFilterProps.Token): string {
  const escapedValue = escapeSpecialCharacters(token.value);
  if (token.operator === ":") {
    return `${token.propertyKey}:${escapedValue}~ or *${token.propertyKey}:${escapedValue}*`;
  }
  return `${token.propertyKey}:"${token.value}"`;
}

function escapeSpecialCharacters(value: string): string {
  const specialChars = /[+\-=&|><!(){}[\]^"~*?:\\/]/g;
  return value.replace(specialChars, "\\$&");
}

function formatFreeTextToken(token: PropertyFilterProps.Token): string {
  const escapedValue = escapeSpecialCharacters(token.value);
  return `${escapedValue}~ OR *${escapedValue}*`;
}

/**
 * Generates the basic structure of an OpenSearch query.
 */
export function buildBasicSearchQuery(query: PropertyFilterProps.Query, pageSize: number, currentPage: number, entityType: string, businessGroupSlug?: string, sortingField?: string, sortingDescending?: boolean, customAttributes?: []): any {
  const searchQuery = JSON.parse(stringifySearchQuery(query, entityType, customAttributes));
  const dateFilters = stringifyDateFilters(query.tokens, entityType);

  const finalQuery: any = {
    from: (currentPage - 1) * pageSize,
    size: pageSize,
    script_fields: entityType === ENTITY_TYPE.CAMPAIGNS ? getCampaignCalculatedFields() : {},
    _source: true,
    query: {
      bool: {
        must: [searchQuery],
        filter: [
          {
            terms: {
              businessGroupSlug: [businessGroupSlug],
            },
          },
          ...dateFilters,
        ],
      }
    }
  };

  if (sortingField) {
    if (sortingField === "fixedplannedspend" || sortingField === "variableplannedspend") {
      const scriptSource = sortingField === "fixedplannedspend" ? createSumScript(fixedSpendFields) : createSumScript(variableSpendFields);

      finalQuery["sort"] = [{
        _script: {
          type: "number",
          script: {
            lang: "painless",
            source: scriptSource
          },
          order: sortingDescending ? "asc" : "desc"
        }
      }];
    } else {
      const fieldKey = [dateConfig, numericFieldConfig].some(config => config[entityType]?.includes(sortingField))
        ? sortingField
        : `${sortingField}.keyword`;

      finalQuery["sort"] = [{ [fieldKey]: { order: sortingDescending ? "asc" : "desc" } }];
    }
  } else if (!query.tokens.length) {
    finalQuery["sort"] = [{ "creationDate": { order: "desc" } }];
  }


  return finalQuery;
}


export function buildSuggestionsQuery(
  detail: PropertyFilterProps.LoadItemsDetail,
  searchParams: PropertyFilterProps.Query,
  entityType: string,
  businessGroupSlug?: string,
  customAttributes?: [],
  size = 100,
) {
  const { filteringProperty, filteringText } = detail;

  const searchQuery = JSON.parse(stringifySearchQuery(searchParams, entityType, customAttributes));


  if (!filteringProperty || !filteringProperty.key) {
    throw new Error(
      "Filtering property details are required for building suggestions query."
    );
  }

  const baseQuery: any = {
    bool: {
      must: [],
      filter: [
        {
          terms: {
            businessGroupSlug: [businessGroupSlug],
          },
        },
      ],
    },
  };

  if (filteringText && filteringText.trim() !== "") {
    baseQuery.bool.must.push({
      match_phrase_prefix: {
        [filteringProperty.key]: filteringText,
      },
    });
  } else {
    baseQuery.bool.must.push({
      match_all: {},
    });
  }

  const query: any = {
    query: {},
    collapse: {
      field: `${filteringProperty.key}.keyword`,
    },
    _source: [filteringProperty.key],
    size: size,
  };

  if (searchQuery && !("match_all" in searchQuery)) {
    query.query = {
      function_score: {
        query: baseQuery,
        functions: [
          {
            filter: searchQuery,
            weight: 2,
          },
        ],
        boost_mode: "multiply",
      },
    };
  } else {
    query.query = baseQuery;
  }

  return query;
}

export function transformOpensearchResponse(data: any, page: number, pageSize: number, isCampaign = false) {
  const total = data["search_results"]["hits"]["total"];
  let hits = data["search_results"]["hits"]["hits"];

  const pageDetails = {
    number: page,
    numberOfElements: hits.length,
    size: pageSize,
    totalElements: total["value"],
    totalPages: Math.ceil(total["value"] / pageSize),
  };

  hits = hits.map((hit: any) => ({
    metadata: {
      createdBy: hit["_source"]["createdBy"],
      creationDate: hit["_source"]["creationDate"],
      lastUpdatedBy: hit["_source"]["lastUpdatedBy"],
      lastUpdatedDate: hit["_source"]["lastUpdatedDate"],
    },
    ...hit["_source"],
    ...(isCampaign && {
      spendTotals: {
        fixedSpend: hit.fields?.fixedSpend ? hit.fields.fixedSpend[0] : null,
        variableSpend: hit.fields?.variableSpend ? hit.fields.variableSpend[0] : null,
      }
    })
  }));

  return {
    items: hits,
    page: pageDetails
  };
}


export function getCampaignCalculatedFields() {
  return {
    fixedSpend: {
      script: {
        lang: "painless",
        source: createSumScript(fixedSpendFields)
      }
    },
    variableSpend: {
      script: {
        lang: "painless",
        source: createSumScript(variableSpendFields)
      }
    }
  };
}

function createSumScript(fields: string[]): string {
  return `
    double sum = 0;
    for (String field : new String[] {${fields.map(field => `'${field}'`).join(", ")}}) {
      if (doc.containsKey(field) && !doc[field].empty) {
        sum += doc[field].value;
      }
    }
    return sum;
  `;
}

/**
 * Parses the search query parameters from the provided search string and returns an array of tokens.
 *
 * @param search - The search string containing query parameters.
 * @returns An array of tokens representing the key-value pairs in the search string.
 *          Each token is an object with properties: `propertyKey`, `operator`, and `value`.
 *
 * @example
 * const searchString = '?key1=value1&key2=value2';
 * const tokens = getTokensFromSearchParam(searchString);
 * -> tokens = [
 *    { propertyKey: 'key1', operator: '=', value: 'value1' },
 *    { propertyKey: 'key2', operator: '=', value: 'value2' }
 *  ]
 */
export function getTokensFromSearchParam(
  search: string
): PropertyFilterProps.Query["tokens"] {
  search = decodeURI(search);

  if (!search) {
    return [];
  } else if (search.split("=").length === 2) {
    search = search.split("?")[1];
    const [key, value] = search.split("=");

    return [{ propertyKey: key, operator: "=", value: value }];
  }

  const tokens: { [key: string]: string, value: string, operator: string }[] = [];

  search.split("?")[1].split("&").forEach(param => {
    const [key, value] = param.split("=");
    tokens.push({ propertyKey: key, operator: "=", value: value });
  });

  return tokens as PropertyFilterProps.Query["tokens"];
}
