import { SelectedBGLocalStorage } from "../../data/constants/localStorage";
import { ISettingsMap, ITableSettings } from "../../data/interfaces/ITable";

export const getSavedPreferencesFromLocalStorage = (
  resourceName: string,
  visibleContentDefault: any
):ITableSettings => {
  const tableSettings: string | null = window.localStorage.getItem(
    `${resourceName}Table`
  );
  const parsedSettings: ISettingsMap = tableSettings
    ? JSON.parse(tableSettings)
    : {};
  const currBusinessGroupData: any = localStorage.getItem(
    SelectedBGLocalStorage.SELECTED_BG_NAME
  );
  const parsedBGData = currBusinessGroupData
    ? JSON.parse(currBusinessGroupData)
    : "";
  const currBusinessGroup = parsedBGData
    ? parsedBGData?.businessGroupSlug
    : null;

  let preferencesData = {
    pageSize: 10,
    wraplines: true,
    visibleContent: visibleContentDefault,
  };
  if (parsedSettings[currBusinessGroup])
    preferencesData = parsedSettings[currBusinessGroup];

  return preferencesData;
};
