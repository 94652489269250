import React, { createContext, useContext, ReactNode, useMemo } from "react";
import { AuthContext } from "./AuthContext";
import { createCampaignRepository } from "../repositories/CampaignRepository";
import ICampaignRepository from "../interfaces/ICampaignRepository";
import IFlightPlanRepository from "../interfaces/IFlightPlanRepository";
import { createFlightPlanRepository } from "../repositories/FlightPlanRepository";
import IMediaItemRepository from "../interfaces/IMediaItemRepository";
import { createMediaItemRepository } from "../repositories/MediaItemRepository";

interface RepositoryContextType {
  campaignRepo: ICampaignRepository;
  flightPlanRepo: IFlightPlanRepository;
  mediaItemRepo: IMediaItemRepository;
}

export const RepositoryContext = createContext<RepositoryContextType>(
  {} as RepositoryContextType
);

export const RepositoryProvider = ({ children }: { children: ReactNode }) => {
  const {
    getIdToken,
    selectedBG,
    customCampaignAttributes,
    customMediaItemAttributes,
  } = useContext(AuthContext);

  const campaignRepo = useMemo(
    () => createCampaignRepository(getIdToken, selectedBG, customCampaignAttributes?.attributes),
    [getIdToken, selectedBG, customCampaignAttributes]
  );

  const flightPlanRepo = useMemo(
    () => createFlightPlanRepository(getIdToken, selectedBG),
    [getIdToken, selectedBG]
  );

  const mediaItemRepo = useMemo(
    () => createMediaItemRepository(getIdToken, selectedBG, customMediaItemAttributes?.attributes),
    [getIdToken, selectedBG, customMediaItemAttributes]
  );

  const value = useMemo(
    () => ({
      campaignRepo,
      flightPlanRepo,
      mediaItemRepo,
    }),
    [campaignRepo, flightPlanRepo]
  );

  return (
    <RepositoryContext.Provider value={value}>
      {children}
    </RepositoryContext.Provider>
  );
};
