import React, { useContext, useEffect, useState } from "react";
import { ButtonProps, CollectionPreferencesProps } from "@cloudscape-design/components";
import TaCSTableCloudscape from "../../../components/table/TaCSTableCloudscape";
import {
  BUSINESS_ATTRIBUTES_TABLE_COLUMNS,
  PREFERRED_VISIBLE_COLUMNS_ATTRIBUTES,
  VISIBLE_CONTENT_OPTION_PREFERENCE_ATTRIBUTES,
} from "../config";
import { AuthContext } from "../../../context/AuthContext";
import { useFetchBusinessData } from "../../../client/client";
import { useNavigate } from "react-router-dom";
import { BUSINESS_ROUTE_PATHS, ITEM_UNIQUE_ID, REQUEST_METHOD } from "../../../data/constants/common";
import { getTablePreferencesFromLocalStorage } from "../../../data/config/commonDashboardConfig";
import { Attribute } from "../Interface";
import { IBusinessGroup } from "../../../data/interfaces/IUser";

type Props = {
  getIdToken: () => string;
  selectedBG: IBusinessGroup | null;
}


export const ManageAttributes: React.FC<Props> = ({ getIdToken, selectedBG }) => {

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<Attribute[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const [tablePreferences, setTablePreferences] = useState<
    CollectionPreferencesProps.Preferences>(() => {
      return getTablePreferencesFromLocalStorage(
        "attributes",
        PREFERRED_VISIBLE_COLUMNS_ATTRIBUTES
      );
    });
  const navigate = useNavigate();

  const { isLoading, data, serverError, refresh }
    = useFetchBusinessData({
      urlPath: `${BUSINESS_ROUTE_PATHS.ATTRIBUTES}/?currPage=${currentPage}&pageSize=${tablePreferences.pageSize}`,
      method: REQUEST_METHOD.GET,
      getTokenFn: getIdToken,
    });

  useEffect(() => {
    setTableData(data);
    setLoadingTableData(false);
  }, [isLoading, currentPage, tablePreferences]);

  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    navigate(BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC + BUSINESS_ROUTE_PATHS.CREATE);
  };

  const handleAttributeTableActions = (): void => {

    navigate(BUSINESS_ROUTE_PATHS.BUSINESS_LOGIC + BUSINESS_ROUTE_PATHS.EDIT, {
      state: {
        ...selectedItems[0],
      },
    });
  };

  return (
    <TaCSTableCloudscape
      tableHeading="Attributes"
      filteringPlaceholder="Search for attributes"
      businessProperty="attributes"
      primaryActionLabel="Create attribute"
      isLoading={loadingTableData}
      data={tableData}
      serverError={serverError}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      tablePreferences={tablePreferences}
      setTablePreferences={setTablePreferences}
      columnDefinitions={BUSINESS_ATTRIBUTES_TABLE_COLUMNS}
      items={tableData?.attributes}
      visibleContentPreferenceOptions={VISIBLE_CONTENT_OPTION_PREFERENCE_ATTRIBUTES}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      handleTablePrimaryAction={handleOnCreate}
      handleTableActions={handleAttributeTableActions}
      refetch={refresh}
      itemId={ITEM_UNIQUE_ID.ATTRIBUTE}
      idToken={getIdToken}
      customAttributes={null}
      selectedBG={selectedBG}
    />
  );
};

export const ManageAttributesDashboard = () => {
  const { getIdToken, selectedBG, } = useContext(AuthContext);

  return (
    <ManageAttributes getIdToken={getIdToken} selectedBG={selectedBG} />
  );
};
